import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const HeroBanner = ({
	heading,
	content,
	btnCta1,
	btnCta2,
	bgImage,
	bannerLogos,
	twoColumn = false,
	tagline = "",
	showUnderline = true,
	textAlign = "start",
	columns = 6,
}) => {
	return (
		<section
			className="d-flex align-items-center"
			style={bannerWrapper(bgImage, bannerLogos)}
		>
			<Container>
				<Row className={`justify-content-${textAlign}`}>
					{twoColumn ? (
						<>
							<Col lg={6} className={`mb-4 mb-lg-0 text-${textAlign}`}>
								<span className="text-white">{tagline}</span>
								<h1 className="my-auto text-white pb-2">{heading}</h1>
							</Col>
							<Col lg={6} className="mb-4 mb-lg-0 text-start text-md-start">
								<div
									className="text-white py-4"
									dangerouslySetInnerHTML={{ __html: content }}
								></div>
								<div className="d-flex align-items-center">
									<Button
										as={Link}
										href={btnCta1.url}
										size="lg"
										className="px-4 py-3 me-3 montserrat-bold fs-6"
										target={btnCta1.target}
										variant="secondary"
									>
										{btnCta1.title}
									</Button>
									<Button
										as={Link}
										href={btnCta2.url}
										size="lg"
										className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-white border-white"
										target={btnCta2.target}
									>
										{btnCta2.title}
									</Button>
								</div>
							</Col>
						</>
					) : (
						<Col
							md={9}
							xxl={columns}
							className={`mb-4 mb-lg-0 text-${textAlign}`}
						>
							<h1
								className={`my-auto text-white pb-2 ${
									showUnderline ? "banner-heading" : ""
								}`}
							>
								<span>{heading}</span>
							</h1>
							{/* {showUnderline && (
								<hr className="bg-primary border-0" style={hrStyle()} />
							)} */}
							<div
								className="text-white py-4"
								dangerouslySetInnerHTML={{ __html: content }}
							></div>
							{((btnCta1 && btnCta1.url) || (btnCta2 && btnCta2.url)) && (
								<div className="d-flex align-items-center">
									<Button
										as={Link}
										href={btnCta1.url}
										size="lg"
										className="px-4 py-3 me-3 montserrat-bold fs-6"
										target={btnCta1.target}
										variant="secondary"
									>
										{btnCta1.title}
									</Button>
									<Button
										as={Link}
										href={btnCta2.url}
										size="lg"
										className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-white border-white"
										target={btnCta2.target}
									>
										{btnCta2.title}
									</Button>
								</div>
							)}
						</Col>
					)}
				</Row>
				<Row>
					<Col lg={10}>
						{" "}
						{bannerLogos && bannerLogos.length > 0 && (
							<Row className=" align-items-center mt-7 justify-content-center justify-content-md-start  g-3  ">
								{bannerLogos.map((item, i) => (
									<Col xs={6} sm={3} md={2}>
										<GatsbyImage
											className="w-100 h-auto"
											image={
												item.bannerLogo?.node?.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={item.bannerLogo?.node?.altText}
											key={i}
										/>
									</Col>
								))}
							</Row>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const bannerWrapper = (bgImage, bannerLogos) => ({
	marginTop: "-105px",
	paddingTop: "210px",
	paddingBottom: bannerLogos && bannerLogos.length > 0 ? "60px" : "150px",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	minHeight: "660px",
	background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

const hrStyle = () => ({
	height: "12px",
	maxWidth: "456px",
	opacity: 1,
	marginTop: "-12px",
});

export default HeroBanner;
